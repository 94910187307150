import React, { Component } from 'react'
import * as contentful from 'contentful'
import ApiKey from './constants/contentful'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Top from './pages/Top'
import AllNews from './pages/AllNews'
import Detail from './pages/Detail'
import Nomatch from './pages/Nomatch'
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'
import Contact from './pages/Contact'
import Reservation from './pages/Reservation'
import Company from './pages/Company'
import Sponsors from './pages/Sponsors'
import 'ie-buster/dist/ie-buster.min.js'

class App extends Component {
  client = contentful.createClient(ApiKey)
  state = { news: [], sponsor: [], companies: [] }
  componentDidMount() {
    this.client
      .getEntries({
        order: '-sys.createdAt',
        'sys.contentType.sys.id': 'newsPost'
      })
      .then((response) => {
        this.setState({
          news: response.items
        })
      })
    this.client
      .getEntries({
        order: '-sys.createdAt',
        'sys.contentType.sys.id': 'sponsor'
      })
      .then((response) => {
        this.setState({
          sponsor: response.items
        })
      })
    this.client
      .getEntries({
        order: '-sys.createdAt',
        'sys.contentType.sys.id': 'companies'
      })
      .then((response) => {
        this.setState({
          companies: response.items
        })
      })
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <div className="mainFlex" id="top">
            <Navbar />
            <div className="mainColumn">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Top news={this.state.news} />}
                />
                <Route
                  exact
                  path="/news/:id"
                  render={() => <Detail news={this.state.news} />}
                />
                <Route
                  exact
                  path="/news"
                  render={() => <AllNews news={this.state.news} />}
                />
                <Route path="/reservation" component={Reservation} />
                <Route
                  exact
                  path="/sponsors"
                  render={() => <Sponsors data={this.state.sponsor} />}
                />
                <Route path="/company" component={Company} />
                <Route path="/contact" component={Contact} />

                <Route path="*" component={Nomatch} />
                {/* <Nomatch /> */}
              </Switch>
            </div>

            <Footer />
          </div>
        </BrowserRouter>
      </>
    )
  }
}

export default App
