import 'react-app-polyfill/ie11'
import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/application.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
