import React from 'react'
import TabComp from '../Components/TabComp'
import Title from '../Components/Title'

const AllNews = (props) => {
  return (
    <>
      <div className="main">
        <div className="paper">
          <Title engTitle="ALL NEWS" subTitle="すべて" />
        </div>
        <TabComp news={props.news} />
        <div className="blank"></div>
      </div>
    </>
  )
}

export default AllNews
