import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaExternalLinkSquareAlt
} from 'react-icons/fa'

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <div className="toTopLogo">
        <HashLink to="/#top">
          <p className="ichisanlabo">13LABO</p>
          <p className="subIchisanlabo">- ichisanlabo -</p>
        </HashLink>
      </div>
      <div className="navbarBackground">
        <div className="navbar">
          <ul className="leftNavbar">
            <HashLink to="/#top">
              <li>TOP</li>
            </HashLink>
            <HashLink to="/news#top">
              <li>NEWS</li>
            </HashLink>
            <HashLink to="/reservation#top">
              <li>RESERVATION</li>
            </HashLink>
            <HashLink to="/sponsors#top">
              <li>SPONSORS</li>
            </HashLink>
            <HashLink to="/company#top">
              <li>COMPANY</li>
            </HashLink>
            <HashLink to="/contact#top">
              <li>CONTACT</li>
            </HashLink>
            <a
              href="https://note.com/13labo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li className="blogs">
                BLOG
                <FaExternalLinkSquareAlt className="blogIcon" />
              </li>
            </a>
          </ul>
          <ul className="rightNavbar">
            <a
              href="https://twitter.com/13LABO_cafe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaTwitter />
              </li>
            </a>
            <a
              href="https://www.facebook.com/13labo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaFacebookF />
              </li>
            </a>
            <a
              href="https://www.instagram.com/13labo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaInstagram />
              </li>
            </a>
          </ul>
        </div>
      </div>
      <div className="phoneNavbar">
        <div className="toTopLogo2">
          <HashLink to="/#top">
            <p className="ichisanlabo">13LABO</p>
            <p className="subIchisanlabo">- ichisanlabo -</p>
          </HashLink>
        </div>
        <div
          className={`btn-trigger ${isMenuOpen ? 'open' : 'close'}`}
          id="menu"
          onClick={() => {
            setMenuOpen(!isMenuOpen)
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`menuContent ${isMenuOpen ? 'openMenu' : 'closeMenu'}`}>
          <ul className="menus">
            <HashLink
              to="/#top"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              <li>TOP</li>
            </HashLink>
            <HashLink
              to="/news#top"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              <li>NEWS</li>
            </HashLink>
            <HashLink
              to="/reservation#top"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              <li>RESERVATION</li>
            </HashLink>
            <HashLink
              to="/sponsors#top"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              <li>SPONSORS</li>
            </HashLink>
            <HashLink
              to="/company#top"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              <li>COMPANY</li>
            </HashLink>
            <HashLink
              to="/contact#top"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              <li>CONTACT</li>
            </HashLink>
            <a
              href="https://note.com/13labo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li className="blogs">
                BLOG
                <FaExternalLinkSquareAlt className="blogIcon" />
              </li>
            </a>
          </ul>
          <ul className="phoneMenuIcons">
            <a
              href="https://twitter.com/13LABO_cafe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaTwitter />
              </li>
            </a>
            <a
              href="https://www.facebook.com/13labo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaFacebookF />
              </li>
            </a>
            <a
              href="https://www.instagram.com/13labo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <FaInstagram />
              </li>
            </a>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Navbar
