import React from 'react'
import { HashLink } from 'react-router-hash-link'

let a

const NewsCard = (props) => {
  if (props.category === 'event') {
    a = 'イベント'
  } else if (props.category === 'intern') {
    a = 'インターン'
  } else if (props.category === 'notice') {
    a = 'お知らせ'
  }
  return (
    <>
      {props.data ? (
        <HashLink to={props.url}>
          <div className="card">
            <img src={props.data.fields.headerImage.fields.file.url} alt="" />
            <p className="firstLine">
              <span className="category">
                <span>{a}</span>
              </span>
              <span className="publishDate">
                {props.data.fields.publishDate}
              </span>
            </p>
            <p className="title">{props.data.fields.title}</p>
          </div>
        </HashLink>
      ) : null}
    </>
  )
}

export default NewsCard
