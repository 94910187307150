import React from 'react'
import Title from '../Components/Title'
import News from '../Components/News'
import { HashLink } from 'react-router-hash-link'
import logo from '../assets/images/logo.png'
import drink from '../assets/images/drink.png'
import food from '../assets/images/food.png'
import wifi from '../assets/images/wifi.png'
import top1 from '../assets/images/top1.jpg'
import top2 from '../assets/images/top2.jpg'
import top3 from '../assets/images/top3.jpg'
import LINE from '../assets/images/LINE.svg'
import LINEIcon from '../assets/images/LINEIcon.png'

const Top = (props) => {
  let dataLayer = (window.dataLayer = window.dataLayer || [])
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', 'UA-139524853-3')
  gtag('config', 'G-P6ZD9ZMNJ3')

  return (
    <>
      <div className="topContainer">
        <div className="topImg">
          <div className="logoContainer">
            <img src={logo} alt="" />
            <p className="topP">MON - FRI</p>
            <p className="topP">3:00p.m. ~ 11:00.p.m</p>
            <div className="iconContainer">
              <img src={wifi} alt="wifi"></img>
              <img src={food} alt="wifi"></img>
              <img src={drink} alt="wifi"></img>
            </div>
          </div>
        </div>

        <div className="main3">
          <Title engTitle="NEWS" subTitle="新着情報" />
          <div className="LINE">
            <p className="flex">
              <p>
                <img src={LINE} alt="LINE" />
              </p>
              <p>はじめました！</p>
            </p>
            <p>フォローして就活イベントなどの情報を最速で手に入れよう！</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://lin.ee/hORoENv"
              className="LINEbutton"
            >
              <button>
                <img src={LINEIcon} alt="" />
                友だち追加
              </button>
            </a>
          </div>
          <News news={props.news} />
          <p>
            <HashLink className="seeMore" to="/News#top">
              SEE MORE
            </HashLink>
          </p>
        </div>

        <div className="container">
          <Title engTitle="ABOUT" subTitle="イチサンラボについて" />
          <div className="aboutContent">
            <div className="img">
              <img src={top1} alt="" />
            </div>
            <div className="info">
              <h3>学生と企業をつなげるコワーキングスペース</h3>
              <p>
                北大前の好立地にあり、電源・Wi-Fi無料、登録不要、打ち合わせやイベント利用などで年間8000人を超える学生に利用されています。企業の方との交流会やイベントも実施しており、学生のキャリア支援も行っています。
              </p>
            </div>
          </div>
          <div className="aboutContent-column-reverse">
            <div className="info">
              <h3>学生運営の学生のためのカフェ</h3>
              <p>
                運営スタッフは北大、北海学園、藤女子などの20名以上の大学生で構成されており、それぞれの仲間が集まるコミュニティを形成しています。
              </p>
            </div>
            <div className="img">
              <img src={top2} alt="" />
            </div>
          </div>
          <div className="aboutContent">
            <div className="img">
              <img src={top3} alt="" />
            </div>
            <div className="info">
              <h3>プロジェクトを積極的に行っています。</h3>
              <p>
                学生内プロジェクトや企業、その他団体との共同プロジェクトのコーディネートを行っています。何か学生と一緒にプロジェクトを行いたいときはお気軽にご連絡下さい。{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="blank"></div>
      </div>
    </>
  )
}

export default Top
