import React from 'react'
import Title from '../Components/Title'
import MiddleTitle from '../Components/MiddleTitle'

const Reservation = () => {
  return (
    <div className="reservation">
      <div className="light">
        <Title engTitle="RESERVATION" subTitle="貸切予約" />
      </div>
      <div className="content">
        <h4>
          <nobr>13LABOは土曜、日曜、祝日の貸し切りが</nobr>
          <nobr>可能です。</nobr>
        </h4>
        <MiddleTitle MiddleTitle="料金" />
        <p>学生　：1000円~/1時間</p>
        <p>社会人　：1500円~/1時間</p>
        <MiddleTitle MiddleTitle="利用時間" />
        {/* <p>平日：　15:00~23:00</p> */}
        <p>土日、祝日：　9:00~22:00</p>
        <MiddleTitle MiddleTitle="注意事項" />
        <div className="announcement">
          <p className="font-weight-600">
            冬季（11月から）は別途暖房費がかかります
          </p>
          <br />
          現在13LABOでは入場制限を行っております。
          <br />
          イベントなどの開催の場合はご協力いただく場合がありますのでご了承ください。
          <br />
          貸し切り利用のご予約はこちらから
        </div>
      </div>

      <div>
        <a
          className="seeMore contact"
          href="https://docs.google.com/forms/d/e/1FAIpQLScGdl0G6AQ1bdOIA0uP22PctcHq4qZLhXCguxZ9X0aULZulfA/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          予約する
        </a>
      </div>

      <iframe
        title="calendar"
        className="calendar"
        frameBorder="0"
        src="https://calendar.google.com/calendar/embed?src=13labo.staff%40gmail.com&ctz=Asia%2FTokyo"
      ></iframe>
      <div className="blank"></div>
    </div>
  )
}

export default Reservation
