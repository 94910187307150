import React from 'react'

const MiddleTitle = (props) => {
  return (
    <div className="middleTitle">
      <div className="box">
        <p>
          <span> {props.MiddleTitle} </span>
        </p>
      </div>
    </div>
  )
}

export default MiddleTitle
