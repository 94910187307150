import React from 'react'
import Title from '../Components/Title'
import MiddleTitle from '../Components/MiddleTitle'
import feature1 from '../assets/images/feature1.png'
import feature2 from '../assets/images/feature2.png'
import feature3 from '../assets/images/feature3.png'
import { HashLink } from 'react-router-hash-link'

const Sponsors = (props) => {
  return (
    <div className="sponsorsContainer">
      <div className="geometry">
        <Title engTitle="SPONSORS" subTitle="スポンサー" />
      </div>
      <div className="content">
        <h4>
          13LABOは学生と社会を結ぶ拠点としてスポンサー様の協賛によって運営されています。
          <br />
          毎日20～30人ほど学生が活動場所として出入りしており、採用マーケティングや学生との共同事業などにご興味のある企業様はお気軽にご連絡ください。
        </h4>

        <div>
          <HashLink className="seeMore" to="/contact#top">
            CONTACT
          </HashLink>
        </div>

        <MiddleTitle MiddleTitle="スポンサー" />
        <div className="sponsorImages">
          {props.data.length ? (
            props.data.map((item) => (
              <a
                href={item.fields.sponsorUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${item.fields.sponsorImage.fields.file.url}?w=200&h=100`}
                  alt=""
                />
                <p>{item.fields.title}</p>
              </a>
            ))
          ) : (
            <p>Loading...</p>
          )}
          {/* 2段3段になるとうまく表示されないのでいったん消します */}
          {/* <div className="sponsorFiction" /> */}
        </div>
        <MiddleTitle MiddleTitle="13LABOの特徴" />
        <div className="featuresContainer">
          <div>
            <img src={feature1} alt="" />
            <h4>学生経営、年間8000人の学生が利用するコワーキングスペース</h4>
            <p>
              スタッフは北大、北海学園、藤女子、北星、武蔵女子といった様々な学生で運営されています。利用者は北大生を中心に市内の大学生が利用しており、日々様々なプロジェクト活動やサークル活動をしています。
            </p>
          </div>
          <div>
            <img src={feature2} alt="" />
            <h4>
              オンラインとリアルが混在する地域に根差したコミュニティを形成
            </h4>
            <p>
              地元の企業のみなさまに支えられ、13LABOは運営されています。学生に地元企業の魅力を知ってもらうための交流会やイベントも実施。また、ICTを活用することで、札幌だけに留まらず、道内各地の学生、企業様とのネットワークを構築しています。
            </p>
          </div>
          <div>
            <img src={feature3} alt="" />
            <h4>若者がチャレンジできるプラットフォームとしての機能</h4>
            <p>
              好奇心を持って活動することを13LABOの文化とし、様々なチャレンジを応援し生み出してきました。学生に対し、活動拠点や活動のアドバイス、ノウハウ、企業や若手社会人、学生同士のマッチングを提供しています。
            </p>
          </div>
        </div>
        <MiddleTitle MiddleTitle="13LABOのサービス" />
        <h4>北海道の採用課題を包括的に解決するサービスを提供しています。</h4>
        <div className="serviceImage">
          <img src="https://i.imgur.com/ej9EK5o.png" alt="" />
          <p className="serviceTitle">
            <span>オンライン採用支援サービス</span>
          </p>
        </div>
        <div className="contentsContainer">
          <div>
            <h4>オンラインイベントの企画運営</h4>
            <p>
              オンラインの企業説明会の企画運営を行います。zoom等ITツールの操作説明、ゴール設定のコンサルティング、広報・集客のサポート、当日のファシリテーターの準備含む企画運営、フィードバックまで含むパッケージを提供いたします。
            </p>
          </div>
          <div>
            <h4>オンライン採用支援</h4>
            <p>
              皆様の会社のオンライン採用の導入支援を行います。日々更新される全国の事例をもとに、学生のニーズの調査、最新ツールの使い方をサポート、代行いたします。
            </p>
          </div>
        </div>

        <div className="serviceImage">
          <img src="https://i.imgur.com/hsfDMYN.jpg" alt="" />
          <p className="serviceTitle">
            <span>13LABOコミュニティサービス</span>
          </p>
        </div>
        <div className="contentsContainer">
          <div>
            <h4>広報/PR/採用マーケティング</h4>
            <p>
              13LABO内で動画を流したり、資料を置いておくことが可能です。日々たくさんの学生が来場するので企業認知に活用できます。SNSも活用可能です。
            </p>
          </div>
          <div>
            <h4>会場利用</h4>
            <p>
              13LABO内で企業主催のイベントを実施することができます。40人ほど入る会場で様々なイベントを実施することが可能です。
            </p>
          </div>
        </div>
        <div className="contentsContainer">
          <div>
            <h4>共同プロジェクト/イベント</h4>
            <p>
              13LABOのコミュニティ内のメンバーと共同プロジェクトを行ったり、13LABO主催の交流イベントにご招待いたします。
            </p>
          </div>
          <div>
            <h4>13LABOキャリアプロジェクト</h4>
            <p>
              大学生向け就活コミュニティを運営しております。
              20~30名程度の母集団に対して説明会やイベントなどを実施できます。
            </p>
          </div>
        </div>

        <p className="toContact">お問い合わせはこちら</p>

        <div>
          <HashLink className="seeMore" to="/contact#top">
            CONTACT
          </HashLink>
        </div>
      </div>
      <div className="blank"></div>
    </div>
  )
}

export default Sponsors
