import React from 'react'
import NewsCard from './NewsCard'

const News = (props) => {
  // let newslist = ''
  let newslist2 = []
  // if (props.news.length && props.news.length <= 4) {
  //   newslist = props.news.map((item) => (
  //     <div key={item.sys.id}>
  //       <NewsCard
  //         url={`/News/${item.sys.id}#detail`}
  //         headerImage={item.fields.headerImage.fields.file.url}
  //         publishDate={item.fields.publishDate}
  //         title={item.fields.title}
  //         category={item.fields.category.fields.slug}
  //       />
  //     </div>
  //   ))
  // } else
  if (props.news.length) {
    for (let i = 0; i < 3; i++) {
      newslist2.push(
        <NewsCard
          key={i}
          url={`/News/${props.news[i].sys.id}#top`}
          // headerImage={props.news[i].fields.headerImage.fields.file.url}
          // publishDate={props.news[i].fields.publishDate}
          data={props.news[i]}
          category={props.news[i].fields.category.fields.slug}
        />
      )
    }
  } else {
    newslist2 = <p>取得中</p>
  }

  return (
    <div className="cardWrapper">
      {/* {newslist} */}
      {newslist2}
    </div>
  )
}

export default News
