import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import NewsCard from '../Components/NewsCard'

class TabComp extends Component {
  constructor() {
    super()
    this.state = { tabIndex: 0 }
  }

  render() {
    const news = this.props.news
    const events = this.props.news.filter(
      (item) => item.fields.category.fields.slug === 'event'
    )
    const intern = this.props.news.filter(
      (item) => item.fields.category.fields.slug === 'intern'
    )
    const notice = this.props.news.filter(
      (item) => item.fields.category.fields.slug === 'notice'
    )

    return (
      <Tabs
        selectedIndex={this.state.tabIndex}
        onSelect={(tabIndex) => this.setState({ tabIndex })}
      >
        <div className="tablist">
          <TabList>
            <Tab className="tab">すべて</Tab>
            <Tab className="tab">イベント</Tab>
            <Tab className="tab">インターン</Tab>
            <Tab className="tab">お知らせ</Tab>
          </TabList>
        </div>
        <TabPanel>
          <CardWrapper contents={news} />
        </TabPanel>
        <TabPanel>
          <CardWrapper contents={events} />
        </TabPanel>
        <TabPanel>
          <CardWrapper contents={intern} />
        </TabPanel>
        <TabPanel>
          <CardWrapper contents={notice} />
        </TabPanel>
      </Tabs>
    )
  }
}

const CardWrapper = (props) => {
  if (props.contents.length % 3 === 1) {
    return (
      <div className="cardWrapper">
        {props.contents.map((item) => {
          const url = `/news/${item.sys.id}#top`
          return (
            <NewsCard
              data={item}
              url={url}
              category={item.fields.category.fields.slug}
              key={item.sys.id}
            />
          )
        })}
        <div className="datas" />
        <div className="datas" />
      </div>
    )
  } else if (props.contents.length % 3 === 2) {
    return (
      <div className="cardWrapper">
        {props.contents.map((item) => {
          const url = `/news/${item.sys.id}#top`
          return (
            <NewsCard
              data={item}
              url={url}
              category={item.fields.category.fields.slug}
              key={item.sys.id}
            />
          )
        })}
        <div className="datas" />
      </div>
    )
  } else {
    return (
      <div className="cardWrapper">
        {props.contents.map((item) => {
          const url = `/news/${item.sys.id}#top`
          return (
            <NewsCard
              data={item}
              url={url}
              category={item.fields.category.fields.slug}
              key={item.sys.id}
            />
          )
        })}
        <div className="datas" />
      </div>
    )
  }
}

export default TabComp
