import React from 'react'
import { Link } from 'react-router-dom'

const Nomatch = () => {
  return (
    <div>
      <p className="notFound">お探しのページは見つかりませんでした。</p>
      <p className="seeMore">
        <Link to="/">TOP</Link>
      </p>
    </div>
  )
}

export default Nomatch
