import React from 'react'
import Title from '../Components/Title'
import News from '../Components/News'

import { HashLink } from 'react-router-hash-link'
import ReactMarkdown from 'react-markdown'

import { useParams } from 'react-router-dom'

const Detail = (props) => {
  let { id } = useParams()
  const mydetail = !!props.news.filter((item) => item.sys.id === id)
    ? props.news.filter((item) => item.sys.id === id)[0]
    : false
  let b

  if (mydetail) {
    if (mydetail.fields.category.fields.slug === 'event') {
      b = 'イベント'
    } else if (mydetail.fields.category.fields.slug === 'intern') {
      b = 'インターン'
    } else if (mydetail.fields.category.fields.slug === 'notice') {
      b = 'お知らせ'
    }
  }

  const info =
    b && b !== 'お知らせ' ? (
      <div className="information">
        <div className="tile">INFORMATION</div>
        <table className="infoTable">
          <tbody>
            <tr>
              <th className="Bold">主催</th>
              <td>{mydetail.fields.organizer}</td>
            </tr>
            <tr>
              <th className="Bold">対象</th>
              <td>{mydetail.fields.target}</td>
            </tr>
            <tr>
              <th className="Bold">日時</th>
              <td>{mydetail.fields.eventDate}</td>
            </tr>
            <tr>
              <th className="Bold">場所</th>
              <td>{mydetail.fields.place}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : (
      ''
    )

  const detailView = mydetail ? (
    <>
      <div className="detailPage">
        <p className="postTitle2">{mydetail.fields.title}</p>
        <div className="title-day">
          <p className="category detailCategory">
            <span>{b}</span>
          </p>
          <span className="publishDate margin-left10">
            {mydetail.fields.publishDate}
          </span>
        </div>
        <div className="sidebar">
          <img
            src={mydetail.fields.headerImage.fields.file.url}
            alt="postImage"
            className="postImage"
          />
          {info}
        </div>
        <div className="maincol">
          <p className="postTitle">{mydetail.fields.title}</p>
          <div className="title-day2">
            <p className="category detailCategory">
              <span>{b}</span>
            </p>
            <span className="publishDate">{mydetail.fields.publishDate}</span>
          </div>

          <div className="contents">
            <div className="body">
              <ReactMarkdown source={mydetail.fields.body} />
            </div>
          </div>
        </div>
      </div>
      <div className="main2">
        <Title engTitle="NEWS" subTitle="新着情報" />
        <News news={props.news} />
        <p>
          <HashLink className="seeMore" to="/News#top">
            SEE MORE
          </HashLink>
        </p>
      </div>
    </>
  ) : (
    // loading view
    <div className="loading">
      Loading...※時間が経っても切り替わらない場合はリロードしてください。
    </div>
  )
  return detailView
}

export default Detail
