import React, { Component } from 'react'
import Title from '../Components/Title'

class Contact extends Component {
  render() {
    return (
      <>
        <div className="leaf">
          <Title engTitle="CONTACT" subTitle="お問い合わせ" />
        </div>
        <form action="https://docs.google.com/forms/u/4/d/e/1FAIpQLSfCk_h2yyuk2553mnhygWEP6kGDYV2mXGSgH8eLc6loyZVz9w/formResponse">
          <div>
            <label for="name">お名前</label>
            　　　
            <input
              id="name"
              className="name"
              type="text"
              name="entry.649159124"
            ></input>
          </div>
          　
          <div>
            <label for="email">メールアドレス</label>
            　　　　
            <input id="email" type="text" name="entry.642105608" />
          </div>
          　
          <div>
            　　　　　　
            <label for="aff" className="aff">
              所属
            </label>
            　　　　
            <input
              id="aff"
              type="text"
              name="entry.1940305713"
              placeholder="(例)〇〇株式会社　人事部"
            />
          </div>
          　
          <div>
            　　　
            <label for="category">お問い合わせの内容</label>　
            <select name="entry.1206450825" placeholder="選択してください">
              <option hidden>選択してください</option>　
              <option value="スポンサーについて">スポンサーについて</option>　{' '}
              <option value="貸し切り利用について">貸し切り利用について</option>
              <option value="見学希望">見学希望</option>　
              <option value="その他">その他</option>　
            </select>
          </div>
          　
          <div className="message">
            　　 　　　　
            <label for="message">メッセージ</label>
            　　　　
            <textarea
              id="message"
              name="entry.1190860898"
              placeholder="メッセージを入力してください。"
            />
          </div>
          　
          <div className="submitContainer">
            <p>1つでも入力されていない場合、送信されません。</p>
            　　　　
            <button
              type="submit"
              name="button"
              value="送信"
              className="seeMore"
            >
              送信
            </button>
          </div>
          <div className="blank"></div>　　　
        </form>
        　
      </>
    )
  }
}

export default Contact
