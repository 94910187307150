import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { SiGooglemaps } from 'react-icons/si'

const Footer = () => {
  return (
    <div className="footer">
      <div className="toTopLogo">
        <Link to="/">
          <p className="ichisanlaboF">13LABO</p>
          <p className="subIchisanlabo">- ichisanlabo -</p>
        </Link>
      </div>
      <p>〒001-0013</p>
      <p>札幌市北区北13条西4丁目1-2志水ビル2階</p>
      <a
        href="https://g.page/13lab?share"
        className="googleMap"
        target="_blank"
        rel="noopener noreferrer"
      >
        <SiGooglemaps className="googleMapIcon" />
      </a>

      <small>
        (c) 2020 <HashLink to="/company#top">株式会社オガワヤ</HashLink>
      </small>
    </div>
  )
}

export default Footer
