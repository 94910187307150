import React from 'react'
import Title from '../Components/Title'
import MiddleTitle from '../Components/MiddleTitle'
import gyawa from '../assets/images/youhei-ogawa.jpg'

const Company = () => {
  return (
    <>
      <div className="goldenRoad">
        <Title engTitle="COMPANY" subTitle="会社概要" />
      </div>
      <div className="content company">
        <div className="message">
          <img src={gyawa} alt="" />
          <div>
            <h2>
              <span>
                <span>株式会社オガワヤ 代表</span>小川 陽平
              </span>
            </h2>
            <h3>13LABOについて</h3>
            <p>
              「Just for
              Fun＝なぜなら僕にはそれが楽しかったから」という意訳ですが、これは13LABOを作るときに初めに思いついた言葉です。
              単純にやってみたい、なんとなく、偶然的にといった動機で始めたことに熱中して楽しくなって自分のキャリアを築いていたということもあると思います。13LABOで大事にしたいスタンスは好奇心を持ってチャレンジしたことを柔軟に継続させることです。
              13LABOは「北13条にある自分だけのLaboratory」とでも言いましょうか。好奇心に従って夜遅くまで自由に使ってほしいという想いで運営しています。
              何かにチャレンジしたい、プロジェクトを進めたい、13LABOを拠点に活動を進めたい、仲間を見つけたい。
              そういった人たちが集まる場所になるといいなと思っています。
            </p>
            <h3>経歴</h3>
            <p>
              1990年北海道札幌市生まれ。
              新卒で行政向けのITコンサルティング会社に入社。北海道で働きたい想いが強く、札幌の教育NPOに転職。
              2018年に独立。現在は就活カフェ13LABOを運営しながら、北海道大学公共政策大学院で勉強中。
              その他高校の探求ゼミ講師、まちづくり業にも関わる。
            </p>
          </div>
        </div>
        <MiddleTitle MiddleTitle="会社概要" />
        <p className="title">会社名</p>
        <p className="lighter">株式会社オガワヤ</p>
        <p className="title">設立</p>
        <p className="lighter">2018年9月12日</p>
        <p className="title">資本金</p>
        <p className="lighter">120万</p>
        <p className="title">所在地</p>
        <p className="lighter">北海道札幌市北区北13条西4丁目1-2</p>
        <p className="title">連絡先</p>
        <p className="lighter">090-2819-9647,yohei@ogawaya.info</p>
        <p className="title">代表取締役</p>
        <p className="lighter">小川陽平</p>
        <p className="title">事業内容</p>
        <p className="lighter">
          広告業、教育事業、NPO・ITに関するコンサルティング業、各種イベント企画業
        </p>
        {/* <MiddleTitle MiddleTitle="メディア" />
        <MiddleTitle MiddleTitle="協力会社" /> */}
      </div>
      <div className="blank"></div>
    </>
  )
}

export default Company
